'use client';

import {
  GoogleTagManager as TPGoogleTagManager,
  GoogleAnalytics
} from '@next/third-parties/google';
import Script from 'next/script';

const GoogleTagManager = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <>
      <TPGoogleTagManager gtmId="GTM-PBJXRR64" />
      <GoogleAnalytics gaId="G-DDY39QZPVP" />

      {/* Event snippet for conversion */}
      {!isDevelopment && (
        <Script id="page-view-gtag" strategy="lazyOnload">
          {`gtag('event', 'conversion', {
          'send_to': 'AW-16553565883/LW5_CMiH26wZELu9rdU9',
          'value': 0.01,
          'currency': 'USD'
          });`}
        </Script>
      )}

      {isDevelopment && (
        <Script id="page-view-gtag-dev" strategy="lazyOnload">
          {`console.log('Development mode: conversion event not sent to avoid charges.');`}
        </Script>
      )}
    </>
  );
};

export default GoogleTagManager;
