import { MenuItem } from '@headlessui/react';
import { Author } from '@prisma/client';
import Image from 'next/image';
import Link from 'next/link';

import { settings } from '@/shared/config';
import { classNames } from '@/shared/lib';
import { ThemeSwitch } from '@/shared/ui';
import { Divider } from '@/shared/ui';

export default function LoggedInMenu({
  user,
  withAccountInfo = false
}: {
  user: Author;
  withAccountInfo?: Boolean;
}) {
  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col">
        {withAccountInfo && (
          <>
            <div className="grid grid-cols-12 pt-2">
              <Image
                className="col-span-2 my-auto rounded-full"
                src={user?.image ?? ''}
                alt={user?.name ?? ''}
                width={40}
                height={40}
              />
              <div className="col-span-10">
                <p className="w-full overflow-hidden text-ellipsis text-sm dark:text-white">
                  {user?.email}
                </p>
                <p className="overflow-hidden text-ellipsis text-nowrap text-sm dark:text-white">
                  {user?.name}
                </p>
              </div>
            </div>
            <Divider />
          </>
        )}
        <MenuItem>
          <Link
            href="/publications/create/landing"
            className="my-4 block w-full transform rounded-lg bg-blue-main px-4 py-2 text-center font-[500] text-white transition-transform active:scale-95 dark:bg-yellow-600"
          >
            Подать объявление
          </Link>
        </MenuItem>

        {[...settings, { path: '/account/info', name: 'Мой кабинет' }].map((category) => (
          <MenuItem key={category.name}>
            <Link key={category.path} className="my-4 text-lg font-[500]" href={category.path}>
              {category.name}
            </Link>
          </MenuItem>
        ))}
      </div>

      <div>
        <MenuItem>
          {({ focus }) => (
            <a
              href="/api/auth/logout"
              className={classNames(
                focus
                  ? 'bg-gray-100 text-gray-900 dark:bg-slate-600'
                  : 'bg-slate-200 text-gray-700 dark:bg-slate-700',
                'col-span-1 mb-2 mt-2 block w-full rounded-lg px-4 py-2 text-center font-[500] dark:text-white'
              )}
            >
              Выйти
            </a>
          )}
        </MenuItem>
        <Divider />
        <div className="my-4 inline-flex w-full justify-between">
          <p className="text-lg text-gray-700 dark:text-gray-100">Тема</p>
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
}
