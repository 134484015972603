import { Consents } from '../model';

export const updateGtagConsents = (consents: Consents) => {
  const consentSettings = {} as Record<string, string>;
  Object.entries(consents).forEach(([key, value]) => {
    consentSettings[key] = value ? 'granted' : 'denied';
  });

  if (typeof window.gtag === 'function') {
    window.gtag('consent', 'update', consentSettings);
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'cookie_consent_update',
    gtm: { uniqueEventId: Date.now() },
    consents: consentSettings,
    timeStamp: new Date().toUTCString()
  });
};

export const checkGtagLoaded = (initializeConsent: () => void) => {
  let attempts = 0;
  const maxAttempts = 10;

  const checkGtag = () => {
    if (typeof window.gtag !== 'function') {
      attempts += 1;
      // eslint-disable-next-line no-console
      console.log(`Attempt ${attempts} to check gtag`);

      if (attempts < maxAttempts) {
        setTimeout(checkGtag, 600);
      } else {
        // eslint-disable-next-line no-console
        console.warn('gtag not available after 10 attempts.');
      }

      return;
    }

    initializeConsent();
  };

  checkGtag();
};

export const applyYandexMetric = (isEnabled: boolean) => {
  if (isEnabled) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'yandex_metric_enabled' });
  } else {
    const yandexScript = document.querySelector('script[src*="mc.yandex"]');
    if (yandexScript) yandexScript.remove();
    window.dataLayer = window.dataLayer.filter((event) => event.event !== 'yandex_metric_enabled');
  }
};
