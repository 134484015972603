'use client';

import { useReportWebVitals } from 'next/web-vitals';

import { sendCustomEvent } from '@/gtag';

export function WebVitals() {
  useReportWebVitals((metric) => {
    // eslint-disable-next-line no-console
    console.log(metric);
    sendCustomEvent({
      action: metric.name,
      value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
      event_label: metric.id, // id unique to current page load
      non_interaction: true // avoids affecting bounce rate.
    });
  });

  return null;
}
