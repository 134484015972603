'use client';

import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { Author } from '@prisma/client';
import Image from 'next/image';
import { Fragment } from 'react';

import { LoggedInMenu } from '@/entities/menu';

export default function DesktopUserMenu({ user }: { user: Author }) {
  return (
    <Menu as="div" className="relative">
      <MenuButton className="mr-2 flex-shrink-0 rounded-full border-2 border-gray-400 p-1">
        <Image
          className="rounded-full"
          src={user?.image ?? '/avatar-stub.svg'}
          alt={user?.name ?? ''}
          width={48}
          height={48}
        />
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="translateZ(0) fixed top-[84px] z-50 h-min w-[320px] origin-top-right transform rounded-xl border-blue-main/35 border-opacity-100 bg-white px-4 shadow-lg ring-1 ring-black ring-opacity-5 will-change-transform focus:outline-none dark:border-slate-200 dark:border-opacity-50 dark:bg-gray-800">
          <LoggedInMenu user={user} />
        </MenuItems>
      </Transition>
    </Menu>
  );
}
