'use client';

import Image from 'next/image';
import React, { useState, useEffect, useCallback } from 'react';

import { ConsentCheckbox, useCookieConsent } from '@/entities/cookies-concent';

import { applyYandexMetric, checkGtagLoaded, updateGtagConsents } from '../lib';
import {
  GRANTED_CONCENTS,
  getInitialConsents,
  loadConsentsFromStorage,
  saveConsentsToStorage
} from '../model';

const CookieConsent = () => {
  const { isVisible, hideConsent, showConsent } = useCookieConsent();
  const [consents, setConsents] = useState(getInitialConsents);

  const initializeConsent = useCallback(() => {
    const savedConsents = loadConsentsFromStorage();
    if (savedConsents) {
      setConsents(savedConsents);
      updateGtagConsents(savedConsents);
      applyYandexMetric(savedConsents.yandex_metric_enabled);
    } else {
      showConsent();
    }
  }, [showConsent]);

  const handleConsentChange = useCallback((category: keyof typeof consents) => {
    setConsents((prevConsents) => ({
      ...prevConsents,
      [category]: !prevConsents[category]
    }));
  }, []);

  const handleAcceptAll = useCallback(() => {
    setConsents(GRANTED_CONCENTS);
    saveConsentsToStorage(GRANTED_CONCENTS);
    updateGtagConsents(GRANTED_CONCENTS);
    hideConsent();
  }, [hideConsent]);

  const handleSave = useCallback(() => {
    saveConsentsToStorage(consents);
    updateGtagConsents(consents);
    hideConsent();
  }, [consents, hideConsent]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return;
    checkGtagLoaded(initializeConsent);
  }, [initializeConsent]);

  return (
    isVisible && (
      <div
        id="cookies-consent"
        className="fixed bottom-0 left-0 right-0 z-[99] m-6 flex flex-wrap gap-6 rounded-2xl bg-white p-6 text-left shadow-lg dark:bg-gray-800 lg:inline-flex"
      >
        <div className="h-full w-[220px]">
          <Image
            src="/dog-cookie.png"
            alt="dog-cookie"
            className="h-auto w-auto"
            width={160}
            height={160}
            loading="eager"
            priority
          />
        </div>
        <div>
          <h3 className="mb-4 text-lg font-semibold">Мы используем cookies</h3>
          <p className="mb-4">Выберите, какие cookies вы хотите разрешить:</p>
          <div className="cookie-categories mb-4 grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
            <ConsentCheckbox
              label="Функциональные cookies"
              checked={consents.functionality_storage}
              onChange={() => handleConsentChange('functionality_storage')}
            />
            <ConsentCheckbox
              label="Персонализационные cookies"
              checked={consents.personalization_storage}
              onChange={() => handleConsentChange('personalization_storage')}
            />
            <ConsentCheckbox
              label="Статистические cookies"
              checked={consents.analytics_storage}
              onChange={() => handleConsentChange('analytics_storage')}
            />
            <ConsentCheckbox
              label="Маркетинговые cookies"
              checked={consents.ad_storage}
              onChange={() => handleConsentChange('ad_storage')}
            />
            <ConsentCheckbox
              label="Яндекс.Метрика"
              checked={consents.yandex_metric_enabled}
              onChange={() => handleConsentChange('yandex_metric_enabled')}
            />
          </div>
          <div className="flex space-x-4">
            <button
              onClick={handleAcceptAll}
              className="rounded-lg bg-blue-main px-4 py-2 font-[500] text-white hover:bg-blue-main/85 dark:bg-yellow-600"
            >
              Принять все
            </button>
            <button
              onClick={handleSave}
              className="rounded-lg bg-gray-600 px-4 py-2 font-[500] text-white hover:bg-gray-700"
            >
              Сохранить выбор
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieConsent;
