export type ConsentCategory =
  | 'functionality_storage'
  | 'personalization_storage'
  | 'analytics_storage'
  | 'ad_storage'
  | 'ad_user_data'
  | 'ad_personalization'
  | 'security_storage'
  | 'wait_for_update'
  | 'yandex_metric_enabled';

export type Consents = Record<ConsentCategory, boolean>;

export const getInitialConsents = (): Consents => ({
  functionality_storage: false,
  personalization_storage: false,
  analytics_storage: false,
  ad_storage: false,
  ad_user_data: false,
  ad_personalization: false,
  security_storage: true,
  wait_for_update: true,
  yandex_metric_enabled: false
});

export const loadConsentsFromStorage = (): Consents | null => {
  const cookieConsents = localStorage.getItem('cookieConsents');
  if (cookieConsents) {
    return JSON.parse(cookieConsents) as Consents;
  }
  return null;
};

export const saveConsentsToStorage = (consents: Consents) => {
  localStorage.setItem('cookieConsents', JSON.stringify(consents));
};

export const GRANTED_CONCENTS = {
  functionality_storage: true,
  personalization_storage: true,
  analytics_storage: true,
  ad_storage: true,
  ad_user_data: true,
  ad_personalization: true,
  security_storage: true,
  wait_for_update: false,
  yandex_metric_enabled: true
};
