import { MenuItem } from '@headlessui/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { settings } from '@/shared/config';
import { classNames } from '@/shared/lib';
import { Divider } from '@/shared/ui';
import { ThemeSwitch } from '@/shared/ui';

export default function LoggedOutMenu() {
  const path = usePathname();

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col">
        <MenuItem>
          <Link
            href="/publications/create/landing"
            className="my-4 block w-full transform rounded-lg bg-blue-main px-4 py-2 text-center font-[500] text-white transition-transform active:scale-95 dark:bg-yellow-600"
          >
            Подать объявление
          </Link>
        </MenuItem>
        {settings.map((category) => (
          <MenuItem key={category.name}>
            <Link key={category.path} className="my-4 text-lg font-[500]" href={category.path}>
              {category.name}
            </Link>
          </MenuItem>
        ))}
      </div>
      <div>
        <MenuItem>
          {({ focus }) => (
            <a
              href={`/api/auth/login?last-visited=${path}`}
              className={classNames(
                focus
                  ? 'bg-gray-100 text-gray-900 dark:text-slate-100'
                  : 'rounded-lg bg-slate-200 text-gray-700 dark:bg-slate-700 dark:text-white',
                'col-span-1 mb-2 mt-2 block w-full px-4 py-2 text-center text-sm font-[500]'
              )}
            >
              Войти
            </a>
          )}
        </MenuItem>

        <Divider className="mt-8" />

        <div className="my-4 inline-flex w-full justify-between">
          <p className="text-sm text-gray-700 dark:text-gray-100">Тема</p>
          <ThemeSwitch />
        </div>
      </div>
    </div>
  );
}
