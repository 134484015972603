'use client';

import React, { useState, ReactNode } from 'react';

import CookieConsentContext from './CookieConsentContext';

export default function CookieConsentProvider({ children }: { children: ReactNode }) {
  const [isVisible, setIsVisible] = useState(false);

  const showConsent = () => {
    setIsVisible(true);
  };

  const hideConsent = () => {
    setIsVisible(false);
  };

  return (
    <CookieConsentContext.Provider value={{ isVisible, showConsent, hideConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
}
