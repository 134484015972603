const settings = [
  {
    name: 'Объявления',
    path: '/publications'
  },
  {
    name: 'Блог',
    path: '/posts'
  },
  {
    name: 'Клиники',
    path: '/clinics'
  }
];

export { settings };
