'use client';

import { Menu, MenuItems, Transition } from '@headlessui/react';
import { Author } from '@prisma/client';
import { Fragment } from 'react';

import { LoggedOutMenu, MenuButton } from '@/entities/menu';
import { LoggedInMenu } from '@/entities/menu';

export default function BurgerMenu({ user }: { user: Author | undefined }) {
  return (
    <Menu as="nav" className="top-1 z-50 lg:hidden">
      <MenuButton />
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="translateZ(0) fixed right-0 top-[80px] z-50 h-[calc(100svh-138px)] w-full origin-top-right transform border-t-2 border-blue-main/35 border-opacity-100 bg-white px-4 shadow-lg ring-1 ring-black ring-opacity-5 will-change-transform focus:outline-none dark:border-slate-200 dark:border-opacity-50 dark:bg-gray-800">
          <div className="h-full py-1">
            {user ? <LoggedInMenu user={user} withAccountInfo /> : <LoggedOutMenu />}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
