'use client';

import { Tab, TabGroup, TabList } from '@headlessui/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';

import { settings } from '@/shared/config';
import { classNames } from '@/shared/lib';

export default function DesktopTabsMenu() {
  const pathname = usePathname();
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    const idx = settings.findIndex((predicate) => pathname === predicate.path);
    setSelectedIndex(idx);
  }, [pathname]);

  return (
    <div className="w-full px-2">
      <TabGroup as={Fragment} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <TabList as="nav" className="flex gap-2 space-x-1 overflow-x-auto px-4 py-1 text-center">
          {settings.map((category) => (
            <Tab
              key={category.path}
              as={Link}
              href={category.path}
              className={({ selected }) =>
                classNames(
                  'relative w-full px-2.5 py-2 leading-4 focus:outline-none dark:text-white',
                  selected && selectedIndex !== -1
                    ? 'font-bold text-letters-black before:bottom-[-6px] before:left-0 before:top-[10px] before:h-3 before:w-3 before:-translate-x-1/2 before:rounded-full before:bg-blue-main dark:text-white dark:before:bg-white md:before:absolute'
                    : 'text-letters-black hover:text-letters-black/[0.60]'
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </TabList>
      </TabGroup>
    </div>
  );
}
