import { BucketName } from '@/lib/s3/types';

export const getBucketUrl = (bucketName: BucketName, imageName: string, subPath?: string) => {
  const isProduction = process.env.NODE_ENV === 'production';

  const bucketNameLookup = {
    'pets-sale-bucket': isProduction
      ? `https://images-20f98.kxcdn.com/${subPath}/${imageName}`
      : `https://pets-sale-bucket.storage-132.s3hoster.by/${subPath}/${imageName}`,
    'pet-avatars': isProduction
      ? `https://petavatars-20f98.kxcdn.com/${imageName}`
      : `https://pet-avatars.storage-132.s3hoster.by/${imageName}`,
    'dogs-blog-bucket': isProduction
      ? `https://dogsblog-20f98.kxcdn.com/${imageName}`
      : `https://dogs-blog-bucket.storage-132.s3hoster.by/${imageName}`
  };

  // const bucketNameLookup = {
  //   'pets-sale-bucket': `https://pets-sale-bucket.storage-132.s3hoster.by/${subPath}/${imageName}`,
  //   'pet-avatars': `https://pet-avatars.storage-132.s3hoster.by/${imageName}`,
  //   'dogs-blog-bucket': `https://dogs-blog-bucket.storage-132.s3hoster.by/${imageName}`
  // };

  return bucketNameLookup[bucketName];
};
