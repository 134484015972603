import { MenuButton } from '@headlessui/react';

import { Icons } from '@/shared/ui';

export default function MenuButtonComponent() {
  return (
    <MenuButton
      data-collapse-toggle="mobile-menu-2"
      type="button"
      className="ml-1 inline-flex items-center rounded-lg p-2 text-sm lg:hidden"
      aria-controls="mobile-menu-2"
      aria-expanded="false"
    >
      {({ open }) => (
        <>
          <span className="sr-only">Раскрыть меню</span>
          {open ? (
            <Icons.Close className="h-4 w-4 text-blue-main dark:text-[#E5EBFB]" />
          ) : (
            <Icons.Burger className="text-blue-main dark:text-[#E5EBFB]" />
          )}
        </>
      )}
    </MenuButton>
  );
}
