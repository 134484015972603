'use client';

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { ThemeProvider } from 'next-themes';

import { CookieConsentProvider } from '@/entities/cookies-concent';

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
      <UserProvider>
        <CookieConsentProvider>{children}</CookieConsentProvider>
      </UserProvider>
    </ThemeProvider>
  );
}
