'use client';

import { useCookieConsent } from '@/entities/cookies-concent';

export default function CookieConsentButton() {
  const { showConsent } = useCookieConsent();
  return (
    <button onClick={showConsent} className="m-0 pt-4 text-sm text-white">
      🍪 Политика Cookies
    </button>
  );
}
