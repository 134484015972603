'use client';

import Script from 'next/script';

const CA_PUB_ID = '3135721177140607';

const AdSense = () => {
  return (
    <>
      <Script
        async
        src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${CA_PUB_ID}`}
        crossOrigin="anonymous"
        strategy="lazyOnload"
        onLoad={() => {
          // eslint-disable-next-line no-console
          console.log('Advert loaded');
        }}
        onError={(error) => {
          // eslint-disable-next-line no-console
          console.error('Advert failed to load: ', error);
        }}
      />
    </>
  );
};

export default AdSense;
