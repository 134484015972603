'use client';

import { UserCircleIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { classNames } from '@/shared/lib';

export const tabs = ['info', 'publications', 'pets', 'policy'] as const;

export type Tab = (typeof tabs)[number];

function BottomMobileAccountTabs() {
  const pathname = usePathname();
  const segments = pathname.split('/');
  const lastSegment = segments[segments.length - 1] as Tab;

  return (
    <div className="fixed bottom-0 left-0 z-50 block h-16 w-full border-t border-gray-200 bg-white dark:border-gray-600 dark:bg-gray-700 md:hidden">
      <div className="mx-auto grid h-full max-w-lg grid-cols-4 font-medium">
        <Link
          href="/account/info"
          type="button"
          className={classNames(
            lastSegment === 'info' ? 'bg-gray-600 dark:bg-gray-800' : '',
            'group inline-flex flex-col items-center justify-center px-5'
          )}
        >
          <UserCircleIcon
            className={classNames(
              lastSegment === 'info'
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'h-8 w-8'
            )}
            aria-hidden="true"
          />
          <span
            className={classNames(
              lastSegment === 'info'
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'whitespace-nowrap text-center text-xs text-gray-500 dark:text-gray-400'
            )}
          >
            Мои данные
          </span>
        </Link>
        <Link
          href="/account/publications"
          type="button"
          className={classNames(
            lastSegment === 'publications' && pathname.includes('account')
              ? 'bg-gray-600 dark:bg-gray-800'
              : '',
            'group inline-flex flex-col items-center justify-center px-5'
          )}
        >
          <svg
            className={classNames(
              lastSegment === 'publications' && pathname.includes('account')
                ? 'fill-yellow-500 dark:fill-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'mb-1 h-7 w-7 fill-gray-500 text-gray-500 dark:fill-gray-400 dark:text-gray-400'
            )}
            viewBox="0 0 16 16"
          >
            <path d="M2 4h11v4h-11v-4z"></path>
            <path d="M2 2h11v1h-11v-1z"></path>
            <path d="M8 13h3v1h-3v-1z"></path>
            <path d="M8 11h5v1h-5v-1z"></path>
            <path d="M8 9h5v1h-5v-1z"></path>
            <path d="M2 13h5v1h-5v-1z"></path>
            <path d="M2 11h5v1h-5v-1z"></path>
            <path d="M2 9h5v1h-5v-1z"></path>
            <path d="M15 2v-2h-15v14.5c0 0.828 0.672 1.5 1.5 1.5h13c0.828 0 1.5-0.672 1.5-1.5v-12.5h-1zM1.5 15c-0.276 0-0.5-0.224-0.5-0.5v-13.5h13v12.5c0 1.5 1 1.5 1 1.5h-13.5z"></path>
          </svg>
          <span
            className={classNames(
              lastSegment === 'publications' && pathname.includes('account')
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'whitespace-nowrap text-center text-xs text-gray-500 dark:text-gray-400'
            )}
          >
            Мои объявления
          </span>
        </Link>
        <Link
          href="/account/pets"
          type="button"
          className={classNames(
            lastSegment === 'pets' ? 'bg-gray-600 dark:bg-gray-800' : '',
            'group inline-flex flex-col items-center justify-center px-5'
          )}
        >
          <svg className="h-8 w-8" viewBox="0 0 24 24">
            <circle
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              cx="12"
              cy="12"
              r="10.5"
            />
            <path
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              d="M12,11h0a3.82,3.82,0,0,1,3.82,3.82v0a1.91,1.91,0,0,1-1.91,1.91H10.09a1.91,1.91,0,0,1-1.91-1.91v0A3.82,3.82,0,0,1,12,11Z"
            />
            <ellipse
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              cx="10.09"
              cy="7.7"
              rx="0.95"
              ry="1.43"
            />
            <ellipse
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              cx="13.91"
              cy="7.7"
              rx="0.95"
              ry="1.43"
            />
            <circle
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              cx="17.25"
              cy="10.57"
              r="0.48"
            />
            <circle
              className={classNames(
                lastSegment === 'pets'
                  ? 'stroke-yellow-500 dark:stroke-yellow-400'
                  : 'stroke-gray-500 dark:stroke-gray-400',
                'fill-none'
              )}
              cx="6.75"
              cy="10.57"
              r="0.48"
            />
          </svg>
          <span
            className={classNames(
              lastSegment === 'pets'
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'whitespace-nowrap text-center text-xs text-gray-500 dark:text-gray-400'
            )}
          >
            Питомцы
          </span>
        </Link>
        <Link
          href="/account/policy"
          type="button"
          className={classNames(
            lastSegment === 'policy' ? 'bg-gray-600 dark:bg-gray-800' : '',
            'group inline-flex flex-col items-center justify-center px-5'
          )}
        >
          <svg
            className={classNames(
              lastSegment === 'policy'
                ? 'fill-yellow-500 dark:fill-yellow-400'
                : 'fill-gray-500 dark:fill-gray-400',
              'h-8 w-8'
            )}
            viewBox="-2 0 19 19"
          >
            <path d="M14.443 4.445a1.615 1.615 0 0 1-1.613 1.614h-.506v8.396a1.615 1.615 0 0 1-1.613 1.613H2.17a1.613 1.613 0 1 1 0-3.227h.505V4.445A1.615 1.615 0 0 1 4.289 2.83h8.54a1.615 1.615 0 0 1 1.614 1.614zM2.17 14.96h7.007a1.612 1.612 0 0 1 0-1.01H2.172a.505.505 0 0 0 0 1.01zm9.045-10.515a1.62 1.62 0 0 1 .08-.505H4.29a.5.5 0 0 0-.31.107l-.002.001a.5.5 0 0 0-.193.397v8.396h6.337a.61.61 0 0 1 .6.467.632.632 0 0 1-.251.702.505.505 0 1 0 .746.445zm-.86 1.438h-5.76V6.99h5.76zm0 2.26h-5.76V9.25h5.76zm0 2.26h-5.76v1.108h5.76zm2.979-5.958a.506.506 0 0 0-.505-.505.496.496 0 0 0-.31.107h-.002a.501.501 0 0 0-.194.398v.505h.506a.506.506 0 0 0 .505-.505z" />
          </svg>
          <span
            className={classNames(
              lastSegment === 'policy'
                ? 'text-yellow-500 dark:text-yellow-400'
                : 'text-gray-500 dark:text-gray-400',
              'whitespace-nowrap text-center text-xs text-gray-500 dark:text-gray-400'
            )}
          >
            Политика
          </span>
        </Link>
      </div>
    </div>
  );
}

export const BottomMobileAccountTabsMenu = BottomMobileAccountTabs;
